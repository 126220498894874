<template>
	<p class="w-9/12 mx-auto text-slate-400 text-lg xl:text-xl">
		I'm a Full Stack Web Developer with a strong passion for technology on all its forms. I' ve started playing with
		tech when I was ten and never stopped since.
		I've started studying computer science and then moved to working as a freelance photographer in 2016.
		I'm a very curious person and I love making things through code or by hands.
		During the pandemic I've decided to follow my passion for tech and started studying Web Development. In 2022
		I've completed a master as Web Developer focussing on Vue.JS and Laravel.
		Currently I'm looking for a new opportunity were keep learning and improving my skills.
	</p>
</template>

<script>
export default {
	name: "LongBioComp",
};
</script>
<style lang="scss" scoped>
p {
	font-family: 'montserrat', sans-serif;
}
</style>
