<template>
	<p class="text-slate-400 text-lg xl:text-2xl text-right">
		Hi, I'm Gerardo Calia and I'm a front-end and back-end web
		developer. I'm on the
		lookout for my next
		challenge in building great sites, applications that engage users and make the web a better place.
	</p>
</template>
<script>
export default {
	name: "IntroComp",
};
</script>

<style lang="scss" scoped>
* {
	font-family: "Montserrat";
}
</style>
